import React from "react";

export default function Hamburger({ toggleMenu, setToggleMenu }) {
  return (
    <div className="flex md:hidden">
      <button
        className={["toggle z-50", toggleMenu ? "active" : ""].join(" ")}
        onClick={() => setToggleMenu((prev) => !prev)}
      ></button>
    </div>
  );
}
