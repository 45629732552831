import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Sidebar from "parts/Sidebar";
import { useDispatch, useSelector } from "react-redux";

import formatThousand from "helpers/formatThousand";
import formatDate from "helpers/formatDate";

import orders from "constants/api/orders";

import Loading from "parts/Loading";

import { statusOrders, fetchOrders, messageOrder } from "store/actions/orders";

function EmptyState() {
  return (
    <section className="flex h-screen items-center">
      <div className="w-5/12 text-center py-12 mx-auto">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/illustration-myclass-empty.jpg`}
          alt="Success join class"
        />
        <h1 className="text-3xl text-gray-900 mt-12">Time to Invest</h1>
        <p className="text-lg text-gray-600 mt-4 mb-8 mx-auto text-center">
          It seems you don’t have any transaction
        </p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="bg-orange-500 hover:bg-orange-400 transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-5"
          href={`${process.env.REACT_APP_FRONTPAGE_URL}/library`}
        >
          Cari Kelas
        </a>
      </div>
    </section>
  );
}

function Congratulations({ data }) {
  return (
    <section className="flex h-screen items-center">
      <div className="w-5/12 text-center py-12 mx-auto">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/illustration-myclass-empty.jpg`}
          alt="Success join class"
        />
        <h1 className="text-3xl text-gray-900 mt-12">Congratulations</h1>
        <p className="text-lg text-gray-600 mt-4 mb-8 mx-auto text-center">
          You have successfully joined our{" "}
          <strong>{data?.metadata?.course_name ?? "Class Name"}</strong> class
        </p>
        <Link
          className="bg-orange-500 hover:bg-orange-400 transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-5"
          to={`/courses/${data.course_id}`}
        >
          Mulai belajar
        </Link>
      </div>
    </section>
  );
}

export default function Transactions() {
  const dispatch = useDispatch();
  const ORDERS = useSelector((state) => state.orders);

  const location = useLocation();

  const params =
    location?.search.length > 0 &&
    location?.search
      ?.substring?.(1, location.length)
      ?.split?.("&")
      ?.reduce?.((acc, item) => {
        const [key, value] = item.split("=");
        acc[key] = value;
        return acc;
      }, {});

  useEffect(() => {
    window.scroll(0, 0);

    dispatch(statusOrders("loading"));
    orders
      .all()
      .then((res) => {
        dispatch(fetchOrders(res.data));
      })
      .catch((err) => {
        dispatch(messageOrder(err?.response?.data?.message ?? "error"));
      });
  }, [dispatch]);

  return (
    <div className="flex">
      <Sidebar></Sidebar>
      <main className="flex-1">
        <div className="px-4 sm:px-16">
          {ORDERS.status === "loading" && <Loading></Loading>}
          {ORDERS.status === "error" && ORDERS.message}
          {ORDERS.status === "ok" &&
            (params.order_id ? (
              <Congratulations data={ORDERS.data[params.order_id]} />
            ) : ORDERS.total > 0 ? (
              <>
                <section className="flex flex-col mt-8 pl-12 md:pl-0">
                  <h1 className="text-xl md:text-4xl text-gray-900 font-medium">
                    Transactions
                  </h1>
                  <p className="text-sm md:text-lg text-gray-600">
                    Keep on track what you've invested
                  </p>
                </section>
                <section className="flex flex-wrap sm:flex-col  mt-8">
                  {Object.values(ORDERS.data)?.map?.((item, index) => {
                    return (
                      <div
                        key={item.id}
                        className="flex flex-wrap justify-start items-center -mx-4 mt-5 mb-4 lg:mb-6 "
                      >
                        <div className="w-full sm:w-2/12 px-4">
                          <img
                            src={item?.metadata?.course_thumbnail ?? ""}
                            alt={item?.metadata?.course_name ?? "Class name"}
                          />
                        </div>
                        <div className="w-auto sm:w-3/12 px-4">
                          <h6 className="text-gray-900 text-lg truncate">
                            {item?.metadata?.course_name ?? "Class name"}
                          </h6>
                          <p className="text-gray-600">
                            {item?.metadata?.course_level ?? "Level"}
                          </p>
                        </div>
                        <div className="w-full sm:w-2/12 px-4">
                          <h6 className="text-gray-900 text-lg">
                            Rp.
                            {formatThousand(item?.metadata?.course_price ?? 0)}
                          </h6>
                        </div>
                        <div className="w-auto sm:w-2/12 px-4">
                          <h6 className="text-gray-900 text-lg">
                            {item?.created_at
                              ? formatDate(item?.created_at)
                              : "-"}
                          </h6>
                        </div>
                        <div className="w-3/12 px-4 flex justify-center">
                          {item?.status === "pending" && (
                            <Link
                              to={`/joined/${item?.course_id}`}
                              className="bg-orange-500 hover:bg-orange-400 transition-all duration-200 focus:outline-none text-white px-6 py-3 mt-4"
                            >
                              Lunasi
                            </Link>
                          )}
                          {item?.status === "success" && (
                            <Link
                              to={`/courses/${item?.course_id}`}
                              className="bg-gray-250 hover:bg-gray-300 transition-all duration-200 focus:outline-none text-gray-900 px-6 py-3 mt-4"
                            >
                              Lihat kelas
                            </Link>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </section>
              </>
            ) : (
              <EmptyState></EmptyState>
            ))}
        </div>
      </main>
    </div>
  );
}
